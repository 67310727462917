define(['app', '$window'], function (app, $window) {

  var eastendFooterLinkColumns = function () {
    var component = {};

    var _config = {
      selectors: {
        toggle: '[data-toggle]',
        levelOneItems: '[data-level=one]',
        levelTwoItems: '[data-level=two]',
        iconCollapse: '[data-icon=collapse]',
        iconExpand: '[data-icon=expand]',
        footerLinkText: '.eastendFooterLinkColumns_levelTwoItemText',
        fastTrackFooterLink: '[data-fastTrack-footer-link]',
        levelOneTitle: '.eastendFooterLinkColumns_levelOneItemTitle',
        levelOneButton: '.eastendFooterLinkColumns_levelOneButton',
        levelOneButtonData: 'data-js-element=eastendFooterLinkColumns_levelOneButton-',
        levelOneFirst: '.eastendFooterLinkColumns_levelOne-first',
        levelTwoLink: '.eastendFooterLinkColumns_levelTwoItemLink',
        levelOneButtonExtra: '[data-js-element="levelOneButton-extra"]',
        levelOneContainerExtra: '[data-js-element="levelOneContainer-extra"]',
        focusableElements: 'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]',
      },
      attributes: {
        toggle: 'data-toggle',
        level: 'data-level',
        key: 'data-key',
        link: 'data-link',
        ariaExpanded: 'aria-expanded',
        extra: 'extra',
      },
      classes: {
        levelTwoExpanded: 'eastendFooterLinkColumns_levelTwo-expanded',
        iconHide: 'eastendFooterLinkColumns_icon-hide',
        nonClickableLink: 'eastendFooterLinkColumns_levelTwoItem-text',
      },
      key: null,
    };
    var _init = function (element) {
      component.element = element;
      component.toggles = component.element.querySelectorAll(component.config.selectors.toggle);
      component.levelOneItems = component.element.querySelectorAll(component.config.selectors.levelOneItems);
      component.levelTwoItems = component.element.querySelectorAll(component.config.selectors.levelTwoItems);
      component.collapseIcons = component.element.querySelectorAll(component.config.selectors.iconCollapse);
      component.expandIcons = component.element.querySelectorAll(component.config.selectors.iconExpand);
      component.footerLinkText = component.element.querySelectorAll(component.config.selectors.footerLinkText);
      component.fastTrackFooterLink = component.element.querySelector(component.config.selectors.fastTrackFooterLink);
      component.levelOneFirst = component.element.querySelector(component.config.selectors.levelOneFirst);
      component.levelOneTitles = component.element.querySelectorAll(component.config.selectors.levelOneTitle);
      component.levelOneButtons = component.element.querySelectorAll(component.config.selectors.levelOneButton);
      component.levelTwoFirst = component.levelOneFirst && component.levelOneFirst.querySelectorAll(component.config.selectors.levelTwoLink);
      component.levelTwoLinks = component.element.querySelectorAll(component.config.selectors.levelTwoLink);
      component.levelOneButtonExtra = component.element.querySelector(component.config.selectors.levelOneButtonExtra);
      component.levelOneContainerExtra = component.element.querySelector(component.config.selectors.levelOneContainerExtra);
      component.previousWidth = component.getWindowWidth();
      component.footerBreakpoint = 900;
      component.bind();
      component.addClickEventListeners();
      component.handleTextProperties();

      if (component.getWindowWidth() > component.footerBreakpoint) {
        component.disableElements(component.levelOneButtons);
        component.enableElements([...component.levelTwoLinks, ...component.levelTwoFirst, component.levelOneButtonExtra]);
      } else {
        component.disableElements(component.levelTwoLinks);
        component.enableElements([...component.levelOneButtons, ...component.levelTwoFirst]);
        component.toggleButtons();
      }

      component.levelOneContainerExtra && component.disableElement(component.levelOneContainerExtra);
    };

    var _addClickEventListeners = function () {
      component.levelOneButtons.forEach(el => el.addEventListener('click', component.toggleView));

      if (component.fastTrackFooterLink) {
        component.fastTrackFooterLink.addEventListener('click', component.triggerGATracking);
      }
    };

    const _bind = () => {
      $window.addEventListener('resize', component.resizeEvent);
    };

    var _triggerGATracking = () => {
      app.publish('tracking/record', 'Fast Track Engagement', 'Clicked Fast Pass | Footer');
    };

    var _toggleView = function (event) {
      event.stopPropagation();
      let target;
      if (this.getAttribute(component.config.attributes.key) === component.config.attributes.extra) {
        target = component.levelOneContainerExtra;
      } else {
        target = component.toggles[this.getAttribute(component.config.attributes.key) - 1];
      }

      if (target && app.element.getAttribute(component.config.attributes.link, target) !== 'true') {

        component.config.key = app.element.getAttribute(component.config.attributes.key, target);

        if (app.element.getAttribute(component.config.attributes.toggle, target) === 'expand') {
          app.element.setAttribute(component.config.attributes.toggle, 'collapse', target);
          component.enableElements(target.querySelectorAll(component.config.selectors.focusableElements));
          if (component.config.key === 'extra') {
            component.enableElement(target);
          }
          this.setAttribute(component.config.attributes.ariaExpanded, 'true');
          component.expandView();
        } else {
          app.element.setAttribute(component.config.attributes.toggle, 'expand', target);
          component.disableElements(target.querySelectorAll(component.config.selectors.focusableElements));
          if (component.config.key === 'extra') {
            component.disableElement(target);
          }
          this.setAttribute(component.config.attributes.ariaExpanded, 'false');
          component.collapseView();
        }
      }
    };

    var _toggleButtons = () => {
      const elements = [...component.levelOneTitles, ...component.levelOneButtons];
      elements.forEach(el => el.hidden = !el.hidden);
      if (!!component.levelOneButtonExtra) {
        component.levelOneButtonExtra.hidden = false;
      }
    };

    var _findItem = function (collection) {
      for (var i = 0, l = collection.length; i < l; i++) {
        if (app.element.getAttribute(component.config.attributes.key, collection[i]) === component.config.key) {
          return collection[i];
        }
      }
    };

    var _findLevelTwo = function () {
      return component.findItem(component.levelTwoItems);
    };

    var _findExpandIcon = function () {
      return component.findItem(component.expandIcons);
    };

    var _findCollapseIcon = function () {
      return component.findItem(component.collapseIcons);
    };

    var _expandView = function () {
      app.element.addClass(component.config.classes.levelTwoExpanded, component.findLevelTwo());
      app.element.removeClass(component.config.classes.iconHide, component.findCollapseIcon());
      app.element.addClass(component.config.classes.iconHide, component.findExpandIcon());
    };

    var _collapseView = function () {
      app.element.removeClass(component.config.classes.levelTwoExpanded, component.findLevelTwo());
      app.element.addClass(component.config.classes.iconHide, component.findCollapseIcon());
      app.element.removeClass(component.config.classes.iconHide, component.findExpandIcon());
    };

    var _handleTextProperties = function () {
      component.footerLinkText.forEach(function (textElement) {
        //All links have a div wrapper which needs to take this class
        textElement.parentNode.parentNode.classList.add(component.config.classes.nonClickableLink);
      });
    };

    const _getWindowWidth = () => {
      return $window.innerWidth;
    };

    const _resizeEvent = () => {
      if (component.getWindowWidth() > component.footerBreakpoint) {
        component.disableElements(component.levelOneButtons);
        component.enableElements([...component.levelTwoLinks, ...component.levelTwoFirst, component.levelOneButtonExtra]);
        if (component.previousWidth < component.footerBreakpoint) {
          component.toggleButtons();
        }
      } else {
        component.enableElements(component.levelOneButtons);
        if (component.previousWidth >= component.footerBreakpoint) {
          component.disableElements(component.levelTwoLinks);
          const openElements = Array.prototype.slice.call(component.element.querySelectorAll(`[${component.config.attributes.toggle}='collapse']`));
          const openFocusableElements = openElements.map(el => Array.prototype.slice.call(el.querySelectorAll(component.config.selectors.levelTwoLink)));
          openFocusableElements.forEach(el => component.enableElements(el));
          component.enableElements(component.levelTwoFirst);
          component.toggleButtons();
        }
      }
      component.previousWidth = component.getWindowWidth();
    };

    const _enableElement = (element) => {
      if (!element) return;
      element.setAttribute('tabindex', '0');
      element.removeAttribute('aria-hidden');
    };

    const _disableElement = (element) => {
      if (!element) return;
      element.setAttribute('tabindex', '-1');
      element.setAttribute('aria-hidden', 'true');
    };

    const _enableElements = (elements) => {
      elements && Array.prototype.slice.call(elements).forEach(element => component.enableElement(element));
    };

    const _disableElements = (elements) => {
      elements && Array.prototype.slice.call(elements).forEach(element => component.disableElement(element));
    };

    component.config = _config;
    component.init = _init;
    component.addClickEventListeners = _addClickEventListeners;
    component.toggleView = _toggleView;
    component.findLevelTwo = _findLevelTwo;
    component.collapseView = _collapseView;
    component.expandView = _expandView;
    component.findExpandIcon = _findExpandIcon;
    component.findCollapseIcon = _findCollapseIcon;
    component.findItem = _findItem;
    component.handleTextProperties = _handleTextProperties;
    component.triggerGATracking = _triggerGATracking;
    component.toggleButtons = _toggleButtons;
    component.getWindowWidth = _getWindowWidth;
    component.resizeEvent = _resizeEvent;
    component.bind = _bind;
    component.enableElement = _enableElement;
    component.disableElement = _disableElement;
    component.enableElements = _enableElements;
    component.disableElements = _disableElements;

    return component;
  };

  return eastendFooterLinkColumns;
});

